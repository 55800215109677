import React from 'react';
import { Box } from 'rebass';
import Layout from '../components/layout/Layout';
import MetaData from '../components/MetaData';
import ThankYouHero from '../sections/Landings/ThankYouHero';

const ThankYouPage = () => (
  <>
    <MetaData
      title='Thank You'
      description='Thanks for submitting your information! We will review your profile and get back to you as soon as possible.'
    />
    <Layout selected='thank-you'>
      <Box className='full-width' bg='nextonLightGrey' minHeight='calc(100vh - 100px)'>
        <ThankYouHero
          title='Thanks for submitting your information!'
          subtitle='We will review your profile and get back to you as soon as possible.'
        />
      </Box>
    </Layout>
  </>
);

export default ThankYouPage;
