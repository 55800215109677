import React from 'react';
import { Box, Flex, Text, Button } from 'rebass';
import { Link as GatsbyLink } from 'gatsby';
import ThankYouBubbles from '../../components/ThankYouBubbles';

const ThankYouHero = ({ title, subtitle }) => (
  <Box className='body-layout'>
    <Flex
      flexWrap='wrap'
      py='75px'
      justifyContent='center'
      textAlign='center'
      style={{ position: 'relative', zIndex: '0' }}>
      <Box px={['0px', '20px']} maxWidth='700px'>
        <Box className='nexton-form'>
          <Text
            color='nextonOceanBlue'
            my='100px'
            fontSize={['36px', '40px']}
            lineHeight='1.3'
            letterSpacing='-1.5px'
            textAlign='center'
            as='h1'
            fontWeight='400'>
            {title}
            <Text color='nextonDarkGrey' mt='30px' fontSize={['24px', '28px']} as='h2' fontWeight='400'>
              {subtitle}
            </Text>
          </Text>
        </Box>
      </Box>
      <Box mt='100px'>
        <Text color='nextonBlue' fontSize={['40px', '50px']} lineHeight='1.16' letterSpacing='-1.5px' mb='30px'>
          About Us
        </Text>
        <Text
          color='nextonDarkGrey'
          fontSize={['24px', '28px']}
          lineHeight='1.3'
          letterSpacing='-1.5px'
          mb='20px'
          maxWidth='900px'>
          We're a successful team innovating in the Recruiting industry and creating the future of remote work. At
          Nexton we connect US companies with the best engineers in Latin America through our talent network.
        </Text>
        <GatsbyLink to='/about'>
          <Button variant='newMain'>LEARN MORE ABOUT US</Button>
        </GatsbyLink>
      </Box>
      <ThankYouBubbles />
    </Flex>
  </Box>
);

export default ThankYouHero;
